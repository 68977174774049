import React from "react"
import 'bootstrap/dist/css/bootstrap.css';
import Footer from '../components/Footer';
import '../styles/index.css';
import {Helmet} from "react-helmet";

function App() {
  return (
    <div>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Jeremie BINET Développeur Freelance</title>
          <meta name="description" content="Jérémie BINET développeur web freelance" />
          <meta name="keywords" content="Jérémie, BINET, web, freelance, développeur, fullstack, react, php" />
          <html lang="fr" />
        </Helmet>
      <header>
        <a href="../">Retour au site</a>
      </header>
      <div className="container error-message">
        Page indisponible (404)
      </div>
      <div className="row fixedh fixedbottom">
          <Footer />
        </div>
    </div>
  );
}
export default App;